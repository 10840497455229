import axios from '@/utils/http';
import lodash from 'lodash';
export const globals = (app) => {
    // 自定义合并策略
    // app.config.optionMergeStrategies.test = (toVal, fromVal) => {

    //   console.log(toVal);
    //   console.log(fromVal);
    // };
    // 全局变量配置
    app.config.globalProperties.$http = axios;
    app.config.globalProperties.$lodash = lodash;
};