<!--
 * @Author: your name
 * @Date: 2021-05-12 16:12:19
 * @LastEditTime: 2021-06-17 11:23:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\App.vue
-->
<template>
  <div class="main">
    <nav-bar v-if="showNav" isManual @goback="goBack" />
    <div class="home-wrapper" :class="{'hasNav':showNav}">
      <router-view v-slot="{ Component }">
        <keep-alive :include="cacheList">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <teleport to="#modals">
      <chooseRoles v-if="dialogs.chooseRoles" />
    </teleport>
    <teleport to="#loading">
      <Loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import { reactive, toRefs, computed, provide, ref } from "vue";
import chooseRoles from "@/components/chooseRoles";
import navBar from "@/components/navBar";
import { routerList } from "@/router";
import { useRoute, useRouter } from "vue-router";
import { checkIsMobile } from "@/utils/commUtil";
import Loading from "@/components/loading/index";
import { useStore } from "vuex";
import { Dialog } from "vant";
export default {
  name: "App",
  components: {
    chooseRoles,
    navBar,
    Loading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      showNav: false,
      dialogs: {
        chooseRoles: false,
      },
    });
    const choseRetain = ref(-1);
    provide("isRetain", choseRetain);
    const loading = computed(() => {
      return store.state.common.loading;
    });
    state.showNav = computed(() => {
      console.log(route,!route.meta.enter,!checkIsMobile())
      return !route.meta.enter && !checkIsMobile();
    });
    const cacheList = computed(() => {
      return routerList
        .filter((item) => item.meta && item.meta.cache)
        .map((item) => item.name);
    });

    const goBack = () => {
      if (route.name === "Follow") {
        Dialog.confirm({
          message: "是否保留此次编辑?",
          confirmButtonText: "保留",
          cancelButtonText: "不保留",
          confirmButtonColor: "#1678FF",
          cancelButtonColor: "#1678FF",
        })
          .then(() => {
            choseRetain.value = new Date();
            router.go(-1);
          })
          .catch(() => {
            choseRetain.value = -1;
            sessionStorage.clear("currentRetainMessage");
            router.go(-1);
          });
      } else {
        router.go(-1);
      }
    };

    return {
      ...toRefs(state),
      cacheList,
      goBack,
      loading,
    };
  },
};
</script>
<style lang="less" scoped>
.main {
  height: 100%;
}
</style>

