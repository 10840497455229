import { createRouter, createWebHistory } from 'vue-router';
import { initWechat } from '@/utils/weChatConfig';
import { queryUrlParams } from '@/utils/commUtil';
import { getCurExternalContact } from '@/utils/weChatConfig';
import { clearUser } from '@/utils/comOperTools';
import { store } from '@/store';
export const routerList = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home'),
    meta: {
      title: '汇学邦',
    },
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import('@/views/client/index'),
    children: [],
    meta: {
      enter: true,
      cache: true, //是否开启keep-alive缓存
      title: '客户画像',
    },
  },
  {
    path: '/follow',
    name: 'Follow',
    component: () => import('@/views/followUp/index'),
    children: [],
    meta: {
      title: '跟进',
    },
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('@/views/addBusiness/index'),
    children: [],
    meta: {
      title: '商机',
    },
  },
  {
    path: '/studentDetails',
    name: 'StudentDetails',
    component: () => import('@/views/studentDetails/index'),
    children: [],
    meta: {
      enter: true,
      cache: true,
      title: '学生详情',
    },
  },
  {
    path: '/contentEngine',
    name: 'ContentEngine',
    component: () => import('@/views/contentEngine/index'),
    children: [],
    meta: {
      enter: true,
      cache: true,
      title: '内容引擎',
    },
  },
  {
    path: '/fastReply',
    name: 'FastReply',
    component: () => import('@/views/fastReply/index'),
    children: [],
    meta: {
      enter: true,
      cache: true,
      title: '快捷回复',
    },
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/conversionOrder/index'),
    children: [],
    meta: {
      title: '转为订单',
    },
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('@/views/edit/index'),
    children: [],
    meta: {
      title: '编辑客户',
    },
  },
  {
    path: '/openApp',
    name: 'OpenApp',
    component: () => import('@/views/openApp/index'),
    children: [],
    meta: {
      title: '开通APP',
    },
  },
  {
    path: '/previewHtml',
    name: 'PreviewHtml',
    component: () => import('@/views/previewHtml'),
    children: [],
    meta: {
      title: '预览',
    },
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('@/views/paySuccess/paySuccessPage'),
    children: [],
    meta: {
      title: '开通成功',
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('@/views/invite/index'),
    children: [],
    meta: {
      enter: true,
      otherPage: true,
      title: '邀请',
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test'),
    children: [],
  },
  {
    path: '/arrearage',
    name: 'Arrearage',
    component: () => import('@/views/arrearage'),
    children: [],
    meta: {
      title: '支付欠款',
    },
  },
];
const router = createRouter({
  // hash模式
  // history: createWebHashHistory(),
  // html模式
  history: createWebHistory(),
  routes: routerList,
});

router.beforeEach((to, from, next) => {
  // 这里要注意需要调用企业微信的接口除入口页面外的其他页面需要到页面中重新初始化配置initWechat
  // 苹果取第一次进入的url（即入口）初始化配置，其他用当前url配置

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const { code } = queryUrlParams();
  const oldCode = localStorage.getItem('code');
  const customerQyWxUserId = localStorage.getItem('customerQyWxUserId');
  const hxbToken = localStorage.getItem('hxbToken');

  if (!store.state.common.locationUrl) {
    const url = location.href.split('#')[0];
    store.commit('common/setLocalUrl', url);
  }

  if (to.meta.enter && code !== oldCode) {
    // 初始化企业微信
    initWechat().then(() => {
      if (to.meta.otherPage) {
        next();
      }
      getCurExternalContact().then((userId) => {
        // 相同的code只能获取一次token
        if (customerQyWxUserId === userId && hxbToken) {
          next();
        } else {
          // 切换用户时获取token
          clearUser();
          store.dispatch('login/getToken', userId).then((isSync) => {
            // isSync 是否同步为后台客户，暂时觉得没用处
            console.log('isSync', isSync);
            next();
          });
        }
      });
    });
  } else {
    next();
  }

  // next();
  // localStorage.setItem('customerId', 340)
  // 返回 false 以取消导航
  // return false
});
export default router;
