import { Notify } from 'vant';
import _ from 'lodash';

export const formatTimeStamp = (data, weeks) => {
  const years = data.getFullYear();
  const months = data.getMonth() + 1;
  const days = data.getDate();
  const hours = data.getHours();
  const minutes = data.getMinutes();
  const day = data.getDay();
  const week = [
    '星期天',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ];
  let timer = '';
  if (weeks) {

    timer = `${years}/${months}/${days}  ${week[day]}`;
  }
  if (years) {
    timer = `${years}/${months}/${days} ${hours >= 10 ? hours : `0${hours}`}: ${
      minutes >= 10 ? minutes : `0${minutes}`
    } `;
  }
  return timer;
};
export const newNotify = (type, message) => {
  Notify({
    type: type,
    message: message,
    className: 'notify-class',
  });
};

// 匹配数组内容
export const matchLabel = (list, oldKey, newKey, val) => {
  const ind = _.findIndex(list, (item) => {
    return item[oldKey] == val;
  });
  if (ind == -1) {
    return;
  }
  return list[ind][newKey]
}


// 获取url query参数
export const queryUrlParams=()=>{
  const searchParamsString=window.location.search.substring(1)
  return searchParamsString.split('&').reduce((searchParams, curKV)=>{
    const [k, v] = curKV.split('=').map(decodeURIComponent)
    searchParams[k] = v
    return searchParams
  }, {})
}

 // 格式化金额
export const formatMoney=(data)=> {
  if (!data && data != 0) return
  return data.toFixed(2)
}

// 格式化毫秒时间
export const formatTimeMs=(time,type)=> {
  if (!time) return ''
  
  const typeFin = type || 'YYYY-MM-DD HH:mm'
  return window.moment(time).format(typeFin)
}
// 格式化时间差
export const formatTimeDiff=(data)=> {
  const $moment = window.moment.duration(data)
  const years = $moment.years()
  const months = $moment.months()
  const days = $moment.days()
  const hours = $moment.hours()
  const minutes = $moment.minutes()
  const seconds = $moment.seconds()
  let timer = ''
  if (years) {
    timer = `${years}年${months}月${days}天${hours}小时${minutes}分钟`
  } else if (months) {
    timer = `${months}月${days}天${hours}小时${minutes}分钟`
  } else if (days) {
    timer = `${days}天${hours}小时${minutes}分钟`
  } else if (hours) {
    timer = `${hours}小时${minutes}分钟`
  } else if (minutes) {
    timer = `${minutes}分钟`
  } else if (seconds) {
    timer = `${seconds}秒`
  }
  return timer
}
// 汉字按照字母分类
export const pySegSort = (arr,empty) => {
  if(!String.prototype.localeCompare)
      return null;

  var letters = "*abcdefghjklmnopqrstwxyz".split('');
  var zh = "阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥扨它穵夕丫帀".split('');

  var segs = [];
  var curr;
  letters.forEach((item, index) => {
    curr = {letter: item, children:[]};
    arr.forEach((n) => {
        if((!zh[index-1] || zh[index-1].localeCompare(n.name) <= 0) && n.name.localeCompare(zh[index]) == -1) {
            n.checked = false
            curr.children.push(n);
        }
   
    });
    if(empty || curr.children.length) {
        segs.push(curr);
        curr.children.sort(function(a,b){
            return a.name.localeCompare(b);
        });
    }
  });
  return segs;
}

export const checkIsMobile=()=>{
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)==null?false:true;
}

// 格式化数字
export const formatNumber=(data)=> {
  if (!data && data != 0) return
  return parseFloat(data.toFixed(2))
}

// 格式化textarea的换行、空格、回车
export const formatTextArea=(value)=>{
  if(!value) return 
  return value.replace(/\n/g, '_@')
              .replace(/\r/g, '_#')
              .replace(/_#_@/g, '<br/>')
              .replace(/_@/g, '<br/>')
              .replace(/\s/g, '&nbsp;')
}