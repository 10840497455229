import { Notify } from 'vant';
export default {
    error(message){
      Notify({
        type:'danger',
        message
      })
    },
    success(message){
      Notify({
        type:'success',
        message
      })
    },
    warning(message){
      Notify({
        type:'warning',
        message
      })
    },
    info(message){
      Notify({
        type:'primary',
        message
      })
    }
  }