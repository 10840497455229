<!--
 * @Author: your name
 * @Date: 2021-05-19 10:25:55
 * @LastEditTime: 2021-06-03 13:45:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\components\loading\index.vue
-->
<template>
<div class="loading">
  <van-overlay :show="show">
    <div class="wrapper" @click.stop>
      <van-loading size="50" />
    </div>
  </van-overlay>
</div>

</template>
<script>
import {Loading,Overlay} from 'vant'
import {reactive,toRefs} from 'vue'
export default {
  components:{    
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  setup() {
    const state=reactive({
      show:true
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.loading{
  :deep(.wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

}
</style>