/*
 * @Author: your name
 * @Date: 2021-05-12 16:12:19
 * @LastEditTime: 2021-06-08 15:29:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\main.js
 */
import { createApp } from 'vue';
import App from './App.vue';
import { globals } from '@/utils/global';
import router from '@/router';
import { store } from '@/store';
import '@/assets/common.less';
import '@/utils/touch';
import moment from 'moment';
// import '@vant/doc/helper/touch-simulator'
// import 'ant-design-vue/lib/button/style';

// import {goLogin} from '@/utils/comOperTools'
// import setRem from '@/utils/setRem';
// setRem();
window.moment = moment;
const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
globals(app);
