/*
 * @Author: your name
 * @Date: 2021-05-14 14:26:24
 * @LastEditTime: 2021-06-09 10:44:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\utils\weChatConfig.js
 */
import { getJsConfigParams } from '@/api';
import notify from '@/vant/notify';
import { getMediaId } from '@/api/common';
let requestNum = 0;
// 企业微信相关接口/配置
const wx = window.wx;

// config相关接口列表
const jsApiList = ['checkJsApi'];
// agentConfig相关接口列表
const agentJsApiList = [
  'getContext',
  'getCurExternalContact',
  'navigateToAddCustomer',
  'sendChatMessage'
];
// 初始化企业微信
export const initWechat = (link) => {
  const url = link||location.href.split('#')[0]
  return new Promise((resolve) => {
    getJsConfigParams(url).then((res) => {
      if (res && res.body) {
        const data = {
          ...res.body,
        };
        config(data).then(() => {
          agentConfig(data).then(() => {
            resolve();
          });
        });
      }
    });
  });
};
export const config = (data) => {
  // const {corpId,timestamp,nonceStr,signature}=data
  return new Promise((resolve) => {
    wx.config({
      // debug:true,
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      appId: data.corpId, // 必填，企业微信的corpID
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
    });
    
    wx.ready(function() {
      // 重置init次数
      // requestNum = 0;
      console.log('config验证成功');
      resolve();
    });
    wx.error(function(res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('config验证失败', res);
      ++requestNum;
      if (requestNum < 3) {
        initWechat();
      }
    });
  });
};
export const agentConfig = (data) => {
  const { corpId, agentId, timestamp, nonceStr, agentSignature } = data;
  return new Promise((resolve) => {
    wx.agentConfig({
      corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
      agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature: agentSignature, // 必填，签名，见附录-JS-SDK使用权限签名算法
      jsApiList: agentJsApiList, //必填，传入需要使用的接口名称
      success: function(res) {
        // 回调
        console.log('agentConfig验证成功', res);
        resolve();
      },
      fail: function(res) {
        console.log('agentConfig验证失败', res);
        if (res.errMsg.indexOf('function not exist') > -1) {
          notify.warning('版本过低请升级');
        }
      },
    });
  });
};

// 判断当前客户端版本是否支持指定JS接口
export const checkJsApi = (jsApiList) => {
  return new Promise((resolve) => {
    wx.checkJsApi({
      jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success: function(res) {
        // 以键值对的形式返回，可用的api值true，不可用为false
        // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        if (res.errMsg == 'checkJsApi:ok') {
          resolve(res.checkResult);
          for (let key in res.checkResult) {
            if (!res.checkResult[key]) {
              notify.error('版本过低请升级');
            }
          }
        } else {
          notify.error('版本过低请升级');
          resolve(false);
        }
      },
    });
  });
};
// 获取当前外部联系人userid
export const getCurExternalContact = () => {
  return new Promise((resolve) => {
    checkJsApi(['getCurExternalContact']).then((result) => {
      if (result && result.getCurExternalContact) {
        wx.invoke('getCurExternalContact', {}, function(res) {
          if (res.err_msg == 'getCurExternalContact:ok') {
            // res.userId //返回当前外部联系人userId
            resolve(res.userId);
          } else {
            //错误处理
            console.log('获取userid错误');
          }
        });
      }
    });
  });
};

// 获取进入H5页面的入口环境
export const getContext = () => {
  return new Promise((resolve, reject) => {
    wx.invoke('getContext', {}, function(res) {
      if (res.err_msg == 'getContext:ok') {
        // entry  = res.entry ; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
        resolve(res.entry);
      } else {
        //错误处理
        reject();
        notify.error('获取入口失败' + res.err_msg);
      }
    });
  });
};
// 聊天工具栏分享消息到当前会话
export const sendChatMessage = (msgtype, content, title) => {
  return new Promise((resolve) => {
    getContext()
      .then(async (entry) => {
        console.log(entry);
        if (
          entry === 'single_chat_tools' ||
          entry === 'group_chat_tools' ||
          entry === 'chat_attachment'
        ) {
          let params = {
            msgtype,
          };
          params[msgtype] = {};
          if (msgtype === 'text') {
            params[msgtype].content = content;
          } else if (
            msgtype === 'image' ||
            msgtype === 'video' ||
            msgtype === 'file'
          ) {
            await getMediaId(content, title).then((res) => {
              if (res && res.body) {
                params[msgtype].mediaid = res.body;
              } else {
                resolve(false);
              }
            });
          } else if (msgtype === 'news') {
            params[msgtype] = content;
          }
          wx.invoke('sendChatMessage', params, (res) => {
            if (res.err_msg == 'sendChatMessage:ok') {
              //发送成功
              resolve();
            } else {
              notify.error('发送失败');
              // notify.error(res.err_msg);
              resolve(false);
            }
          });
        } else {
          resolve(false);
          notify.error('调起当前会话失败');
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};
// 跳转到邀请添加联系人
export const toAddcustomerPage = () => {
  console.log('navigateToAddCustomer', wx);
  return new Promise((resolve) => {
    wx.invoke('navigateToAddCustomer', {}, function(res) {
      resolve(res);
    });
  });
};

// 界面相关操作
// 关闭当前网页
export const closeWindow = () => {
  wx.closeWindow();
};
// 监听返回按钮
// export const goTotalBack = () => {
//   wx.onHistoryBack(function() {
//     return alert('确定要放弃当前页面的修改？');
//   });
// };
