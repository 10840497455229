/*
 * @Author: your name
 * @Date: 2021-05-12 16:12:19
 * @LastEditTime: 2021-08-16 17:34:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\utils\http.js
 */

import axios from 'axios';
import notify from '@/vant/notify';
import {baseURL,version} from './urls'
import { store } from '@/store'
const http = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'X-Client-Version': version,
    'X-Client-Type': 'hxb',
  },
});
// token过期
const pastToken='000303'
// import {newNotify} from './commUtil'
// 统一处理 失败请求返回值
function resolveFailRes(status) {
  if (status >= 500) {
    notify.error('服务器错误，请稍后重试')
  } else if (status >= 400 && status < 500) {
    if (status == 404) {
      notify.error('网络错误，请稍后重试')
    } else {
      notify.error('客户端错误，请刷新页面')
    }
  } else if (status >= 300 && status < 400) {
    notify.error('请求发生重定向，请联系工作人员')
  }
}
// 添加请求拦截器
http.interceptors.request.use(
  function(config) {
    // 判断是否是不需要token的接口
    // 在发送请求之前携带token
    if (!config.notToken) {
      const hxbToken = localStorage.getItem('hxbToken');
      config.headers.common['X-Auth-Token'] = hxbToken;
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    if (response.data.code == '000000') {
      return response.data;
    } else if(response.data.code==pastToken) {
      // token过期
      console.log(1111)
      const customerQyWxUserId = localStorage.getItem('customerQyWxUserId')
      store.dispatch('login/getToken', customerQyWxUserId)
      return response.data
    }else if (response.config.responseType === 'blob') {
      // 处理返回数据流的情况
      return response;
    }else {
      if (!response.data) {
        return;
      }
      notify.error(response.data.message);
      // Promise.reject()
    }
  },
  function(error) {
    // 对响应错误做点什么
    if (error && error.response && error.response.status) {
      resolveFailRes(error.response.status);
    } else {
      if (error.message == '取消了请求') {
        return;
      }
      notify.error('服务器未挂起，请联系后端工作人员');
    }
    return Promise.reject(error);
  }
);

export default http;
