/*
 * @Author: liyao
 * @Date: 2021-06-01 16:09:26
 * @LastEditTime: 2021-06-09 10:07:08
 * @LastEditors: Please set LastEditors
 * @Description: commonStore
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\store\modules\common.js
 */

const state={
  loading:false,
  localUrl:null //当前页面进入时的url
}
const mutations={
  setLoading(state,loading){
    state.loading=loading
  },
  setLocalUrl(state,url){
    state.localUrl=url
  }
}
const actions={
}
export default{
  namespaced:true,
  state,
  mutations,
  actions
}