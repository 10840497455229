import {getToken} from '@/api/index'
import {queryUrlParams} from '@/utils/commUtil'
const state={

}
const mutations={

}
const actions={
  getToken(context,customerQyWxUserId){
    return new Promise((resolve,reject)=>{
      if(!customerQyWxUserId){
        reject()
        return
      }
      const {code}=queryUrlParams()
  
      const params={
        code,
        customerQyWxUserId
      }
      getToken(params).then(res=>{
        if(res&&res.body){
          resolve()
          console.log('getToken',res.body)
          const {isSync,token,positionId}=res.body
          localStorage.setItem('hxbToken',token)
          localStorage.setItem('customerQyWxUserId',customerQyWxUserId)
          localStorage.setItem('positionId',positionId)
          localStorage.setItem('code',code)
          resolve(isSync)
        }
      })
    })
  }
}

export default{
  namespaced:true,
  state,
  mutations,
  actions
}