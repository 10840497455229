/*
 * @Author: liyao
 * @Date: 2021-05-27 11:04:30
 * @LastEditTime: 2021-09-15 14:42:36
 * @LastEditors: sueRimn
 * @Description: urls相关
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\utils\urls.js
 */

const env = process.env.NODE_ENV;
let baseurl = 'http://192.168.0.12:9130/';
if (env === 'development') {
  // 开发环境
  // baseurl = 'http://mapi.huixuebang.com/'
  // baseurl = 'http://192.168.0.12:9130/';
  // 配置代理相关
  baseurl = '/api';
} else {
  //生产环境
  baseurl = 'http://192.168.0.12:9130/';
  baseurl = 'https://api.huixuebang.com/';
}

export const baseURL = baseurl;

// 本地资源地址
export const localUrl = 'https://h5.huixuebang.com';
// 资源地址
export const ossUrl = 'https://hxbang.oss-cn-shanghai.aliyuncs.com/';
// 链接支付
export const payForLinkUrl = 'http://mbackend.huixuebang.com/linkPay';
// 链接支付二维码
export const withOutLogoQr = baseURL + 'hxb-backend/withOutLogoQr';
// 后台版本号
export const version = '4.0.0';
// 转换企业微信code地址
export const redirectQyWxCode = (url, STATE) =>
  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww6fa883cd6786db91&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${STATE ||
    'STATE'}#wechat_redirect`;
