import { getCustomerId } from '@/api/index';
const state = {
  porStatus: false,
  scrollStatus: '',
  currentCurrentCustomerId: '',
  currentPersonal: {
    // 树形结构选择的数据
    name: '',
  },
  currentDiscountsType: 2, // 是否是优惠订单 2 不是
  currentAuditMessage: null, // 优惠订单审核人信息
};
const mutations = {
  setPorStatus: (state, status) => {
    state.porStatus = status;
  },
  setScollStatus: (state, status) => {
    state.scrollStatus = status;
  },
  setCurrentCustomerId: (state, userId) => {
    state.currentCurrentCustomerId = userId;
  },
  setPersonal: (state, obj) => {
    state.currentPersonal = obj;
  },
  setCurrentDiscountsType: (state, num) => {
    // 是否优惠
    state.currentDiscountsType = num;
  },
  setCurrentAuditMessage: (state, obj) => {
    // 是否优惠
    state.currentAuditMessage = obj;
  },
};
const getters = {
  getPorStatus: (state) => state.porStatus,
  getPersonal: (state) => state.currentPersonal,
  getScrollStatus: (state) => {
    return state.scrollStatus;
  },
  getCustomerId: (state) => state.currentCurrentCustomerId,
  sgetCurrentDiscountsType: (state) => state.currentDiscountsType,
};
const action = {
  getCurrentCustomerId: (commit, userId) => {
    return new Promise((resolve, reject) => {
      getCustomerId(userId)
        .then((res) => {
          commit('setCurrentCustomerId', res);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  action,
  getters,
  mutations,
};
