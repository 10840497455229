import http from '@/utils/http';
// 获取学生身份证号
export const getStudentIdCard = (data) => {
  return http.get('hxb-backend/qyWx/idCard', { params: data });
};
export const getJsConfigParams = (data) => {
  return http.post('hxb-backend/qyWx/jsConfigParams', data, { notToken: true });
};
export const getToken = (data) => {
  return http.get(
    'hxb-backend/qyWx/token',
    { params: data },
    { notToken: true }
  );
};
// 用户有效的所属部门和角色
export const getRoles = (data) => {
  return http.get('hxb-backend/manager/roles', { params: data });
};

// 获取客户id
export const getCustomerId = (data) => {
  return http.get(`hxb-backend/qyWx/customerId`, {
    params: data,
  });
};
// 客户详情
export const getCustomerBasicInfo = (customerId, data) => {
  return http.get(`hxb-backend/customer/getCustomerBasicInfo/${customerId}`, {
    params: data,
  });
};
// 跟进详情
export const getCustomerDynamicOut = (customerId, data) => {
  return http.get(`hxb-backend/customer/getCustomerDynamicOut/${customerId}`, {
    params: data,
  });
};
// 商机
export const getCustomerBusinessByCondition = (pageNo, pageSize, data) => {
  return http.post(
    `hxb-backend/customer/getCustomerBusinessByCondition/${pageNo}/${pageSize}`,
    data
  );
};
// 客户资料详情
export const getCustomerDetails = (data) => {
  return http.get(`hxb-backend/customer/getCustomerDetails`, {
    params: data,
  });
};
// 客户开通记录 app 类
export const getCustomerBusinessAllAppOrders = (customerId) => {
  return http.get(
    `hxb-backend/customer/getCustomerBusinessAllAppOrders/${customerId}`
  );
};
// 客户开通记录 考籍类
export const getCustomerBusinessAllExamOrders = (customerId) => {
  return http.get(
    `hxb-backend/customer/getCustomerBusinessAllExamOrders/${customerId}`
  );
};
// 跟进记录
export const addContact = (data) => {
  return http.post(`hxb-backend/contact/addContact`, data);
};
// 更新客户信息
export const updateCustomerInfo = (data) => {
  return http.post(`hxb-backend/customer/updateCustomerInfo`, data);
};
// 添加商机
export const addCustomerBusiness = (data) => {
  return http.post(`hxb-backend/customer/addCustomerBusiness`, data);
};
// 获取商机列表
export const getBusinessTree = (type) => {
  return http.get(`hxb-backend/customer/getBusinessTree/${type}`);
};
// 获取商机某个学校下所有自考本科专业（限考籍商机）
export const getExaminationBySchool = (data) => {
  return http.get(`hxb-backend/customer/getExaminationBySchool`, {
    params: data,
  });
};
// 获取app商机里面课程
export const getAppMajorAllCourse = (data) => {
  return http.get(`hxb-backend/customer/getAppMajorAllCourse`, {
    params: data,
  });
};
// 商机详情
export const getCustomerBusinessById = (businessId) => {
  return http.get(`hxb-backend/customer/getCustomerBusinessById/${businessId}`);
};
// 商机付款
export const getExaminationPay = (businessId) => {
  return http.get(`hxb-backend/customer/getExaminationPay/${businessId}`);
};
// 修改学生信息
export const addUserStudent = (data) => {
  return http.post(`hxb-backend/customer/addUserStudent`, data);
};
// 增加考籍
export const addUserExamination = (data) => {
  return http.post(`hxb-backend/customer/addUserExamination`, data);
};
// 获取考籍列表
export const getExaminationList = (customerId) => {
  return http.get(`hxb-backend/qyWx/customer/${customerId}/examination`);
};
// 获取订单列表
export const getOrderList = (customerId) => {
  return http.get(`hxb-backend/qyWx/customer/${customerId}/order`);
};
// 查询考籍详情
export const getUserExaminationInfo = (userExaminationId) => {
  return http.get(
    `hxb-backend/examination/getUserExaminationInfo/${userExaminationId}`
  );
};
//查询客户身份证
export const findCustomerIdCard = (customerId) => {
  return http.get(`hxb-backend/customer/findCustomerIdCard/${customerId}`);
};
