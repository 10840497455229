<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-31 14:07:40
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-06-03 10:10:08
-->
// 顶部导航
<template>
  <div class="nav_bar">
    <i class="iconfont hxb-icon_jiantou_d back" @click="goBack" />
    <span class="page_tit">{{route.meta.title }}</span>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  props: {
    isManual: {
      type: Boolean,
      default: false,
    },
    isRetain: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      route,
    });
    const goBack = () => {
      if (props.isManual) {
        context.emit("goback");
      } else {
        router.go(-1);
      }
    };
    return {
      ...toRefs(state),
      goBack,
    };
  },
};
</script>
<style lang="less" scoped>
.nav_bar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 88px;
  background-color: #fff;
  font-weight: bold;
  .back {
    font-size: 38px;
    cursor: pointer;
    position: absolute;
    left: 38px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }
  .page_tit {
    display: inline-block;
    line-height: 88px;
    font-size: 36px;
    width: 100%;
    text-align: center;
  }
}
</style>