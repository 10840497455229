import http from '@/utils/http';
import qs from 'qs';
const config = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};
export const getDepartmentList = () => {
  return http.get('hxb-backend/dropdown/getDepartmentList');
};
export const getCompanyList = () => {
  return http.get('hxb-backend/dropdown/getCompanyList');
};
export const getAuditList = () => {
  return http.get('hxb-backend/dropdown/getAuditor');
};
// 标签
export const getLabelByType = (data) => {
  return http.get('hxb-backend/label/getLabelByType', {
    params: data,
  });
};

// 企业微信添加客户到系统
export const addCustomerByQywx = (data) => {
  return http.post('hxb-backend/qyWx/addCustomer', data, config);
};
// 获取消息素材ID
export const getMediaId = (data, title) => {
  const params = {
    objectKey: data,
    title,
  };
  return http.get('hxb-backend/qyWx/mediaId', { params });
};
// 获取批次
export const getAllBatchNo = () => {
  return http.get(`hxb-backend/dropdown/getAllBatchNo`);
};
// 获取学籍Id
export const getRollId = (data) => {
  return http.get('hxb-backend/qyWx/rollId', {
    params: data,
  });
};
// 获取价格体系
export const getPriceSystemList = (data) => {
  return http.get('hxb-backend/dropdown/getPriceSystemList', {
    params: data,
  });
};
// 验证app课程是否可以购买
export const checkBuyAppGoods = (data) => {
  return http.post('hxb-backend/companyOrder/checkBuyAppGoods', data);
};
// 公司订单
export const businessOrderGoods = (data) => {
  return http.post('hxb-backend/companyOrder/businessOrderGoods', data);
};
//微信支付二维码code
export const getPayCode = (platform, sn) => {
  return http.post(`order-api/pay/pagePay/${platform}/${sn}`);
};
// 微信二维码图片
export const withOutLogoQr = (data) => {
  return http.get('hxb-backend/withOutLogoQr', {
    params: data,
  });
};
// 验证学生是否存在
export const checkStudentExist = (data) => {
  return http.post(
    `hxb-backend/customer/checkStudentExist`,
    qs.stringify(data),
    config
  );
};
// 线下支付
export const offLinePay = (data) => {
  return http.post(
    `hxb-backend/companyOrder/offLinePay`,
    qs.stringify(data),
    config
  );
};
// 检测订单是否支付成功
export const checkPaySuccess = (data) => {
  return http.post(
    `hxb-backend/companyOrder/checkPaySuccess`,
    qs.stringify(data),
    config
  );
};
// 取消订单
export const closeOrder = (data) => {
  return http.post(`hxb-backend/customer/closeOrder`, data);
};
// 检测订单是否能支付
export const checkSnPay = (data) => {
  return http.post(
    `hxb-backend/companyOrder/checkSnPay`,
    qs.stringify(data),
    config
  );
};
// 获取不是客户大数据考籍
export const noFindExamination = (data) => {
  return http.get(`hxb-backend/qyWx/examinationByMobiles?${data}`);
};
// 获取不是客户大数据订单
export const noFindOrderList = (data, type) => {
  return http.get(`hxb-backend/qyWx/orderByMobiles?${data}`, {
    params: type,
  });
};

// 免费开通
export const freeSn = (data) => {
  return http.post(
    `hxb-backend/companyOrder/freeSn`,
    qs.stringify(data),
    config
  );
};
// 支付尾款
export const payOweMoney = (data) => {
  return http.post(
    `hxb-backend/companyOrder/payOweMoney`,
    qs.stringify(data),
    config
  );
};
// 网站支付
export const pagePay = (data, platform, sn) => {
  return http.post(
    `order-api/pay/pagePay/${platform}/${sn}`,
    qs.stringify(data),
    config
  );
};
