<template>
  <van-action-sheet v-model:show="isShow" @cancel="closeModel" title="添加至哪个角色">
    <div class="content">
      <p
        v-for="item in roleList"
        :key="item.roleId"
        @click="handleClick(item)"
      >{{item.departmentName}}-{{item.roleName}}</p>
    </div>
  </van-action-sheet>
</template>
<script>
import { reactive, toRefs } from "vue";
import { ActionSheet } from "vant";
import { getRoles } from "@/api";
import { addCustomerByQywx } from "@/api/common";
import qs from "qs";
export default {
  components: {
    [ActionSheet.name]: ActionSheet,
  },
  props: {},
  setup(props, context) {
    const state = reactive({
      isShow: true,
      roleList: [],
    });
    const getroles = () => {
      getRoles({
        valid: true,
      }).then((res) => {
        if (res && res.body) {
          state.roleList = res.body;
        }
      });
    };
    const closeModel = () => {
      context.emit("closeModel");
    };
    const handleClick = (item) => {
      let qywxCustomerId = localStorage.getItem("customerQyWxUserId");
      addCustomerByQywx(
        qs.stringify({
          customerQyWxUserId: qywxCustomerId,
          positionId: item.id,
        })
      ).then((res) => {
        if (res && res.body) {
          localStorage.setItem('positionId',item.id)
          context.emit("success");
          context.emit("closeModel");
        }
      });
    };
    getroles();
    return {
      closeModel,
      handleClick,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 0px 32px 66px 32px;
  text-align: center;
  p {
    border-bottom: 1px solid #eeeeee;
    padding: 36px 0px;
    font-size: 30px;
    color: #333;
  }
  & > p:last-child {
    border-bottom: none;
  }
}
</style>