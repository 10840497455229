
export const clearUser=()=>{
  localStorage.removeItem('hxbToken')
}

// 获取元素的outerWidth padding+width+margin
export const outerWidth=(htmlDom)=>{
  let limarginLeft=parseInt(/\d+/.exec(getTrueStyle(htmlDom,"marginLeft")))//左margin
  let limarginRight=parseInt(/\d+/.exec(getTrueStyle(htmlDom,"marginRight")))//右margin
  return limarginLeft+htmlDom.offsetWidth+limarginRight//真实宽度
}

export const getTrueStyle=(obj,attr)=>{
  if(obj.currentStyle){ //ie
    return obj.currentStyle[attr]
  }
  else{
    return window.getComputedStyle(obj, null)[attr] || document.defaultView.getComputedStyle(obj,null)[attr];
  }
}